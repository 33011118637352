.large-imageR {
    width: 100%;
}

.r-images{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    position: absolute;
    right: 5%;
    top: 0;
    width: 8%
}
.small-image-r {
    width: 100%
}