
.large-imageL {
    width: 100%;

}

.s-images{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    position: absolute;
    left: 5%;
    top: 0;
    width: 8%
}
.small-image {
    width: 100%
}