@import url('./global.css');

/* Icon styles for the first step */
.iconName {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 12px);
    letter-spacing: 0.04em;
    line-height: 130%;
}
.default {
    align-self: stretch;
    flex: 1;
    position: relative;
    overflow: hidden;
}
.icon {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.iconWrapper {
    width: 64px;
    border-radius: 8px;
    background: linear-gradient(135deg, #fff500, #bdff00);
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    box-sizing: border-box;
    z-index: 0;
}
.joinTheWaitlist {
    position: relative;
    letter-spacing: 0.04em;
    line-height: 28.8px;
    font-weight: 900;
}
.signUpFor {
    margin: 0;
}
.signUpForContainer {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 25.6px;
    font-family: 'Source Sans Pro';
}
.joinTheWaitlistParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 1;
    color: #fff;
    font-family: 'Basement Grotesque';
}
.label {
    position: relative;
    letter-spacing: 0.1em;
    line-height: 22.4px;
    text-transform: uppercase;
    font-weight: 600;
}
.labelContainer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.iconName1 {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 9px);
    letter-spacing: 0.04em;
    line-height: 130%;
}
.icon1 {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.text {
    flex: 1;
    position: relative;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 25.6px;
    font-family: 'Source Sans Pro';
    text-align: left;
}
.textInput {
    flex: 1;
    position: relative;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 25.6px;
    font-family: 'Source Sans Pro';
    text-align: left;
    background-color: #23242a;
    border: rgb(0, 0, 0, 0);
    color: #d1d1d2;
    outline: none;
}
.textInput1 {
    flex: 1;
    appearance: none;
    position: relative;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 25.6px;
    font-family: 'Source Sans Pro';
    text-align: left;
    background-color: #23242a;
    border: rgb(0, 0, 0, 0);
    color: #d1d1d2;
    outline: none;
    width: 100%
}
.inputContainer {
    align-self: stretch;
    border-radius: 8px;
    background-color: #23242a;
    border: 1px solid rgb(255, 255, 255, 0.1);
    box-sizing: border-box;
    height: 48px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 12px;
    gap: 8px;
    text-align: center;
    font-size: 18px;
    font-family: 'Material Icons';
}
.textInput:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #d1d1d2;
}
.input {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
.text1 {
    flex: 1;
    position: relative;
    letter-spacing: 0.02em;
    line-height: 25.6px;
}
.icon2 {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-family: 'Material Icons';
}
.inputContainer1 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #23242a;
    border: 1px solid rgb(255, 255, 255, 0.1);
    box-sizing: border-box;
    height: 48px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 12px;
    gap: 8px;
    font-size: 16px;
}
.textInput1:hover {
    cursor: pointer;
}
.formFields {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 2;
    text-align: left;
    font-size: 14px;
    color: #d1d1d2;
    font-family: 'Source Sans Pro';
}
.iconName3 {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 8px);
    letter-spacing: 0.04em;
    line-height: 130%;
}
.button1 {
    position: relative;
    letter-spacing: 0.05em;
    line-height: 19.2px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Source Sans Pro';
    text-align: left;
}
.button {
    align-self: stretch;
    border-radius: 8px;
    background: #daff00 !important;
    height: 48px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 32px 8px 30px;
    box-sizing: border-box;
    gap: 8px;
    z-index: 3;
    font-size: 16px;
}
.button:hover {
    cursor: pointer;
    background: #e8ff67 !important;
}
.button:active {
    cursor: pointer;
    background: #adcc02 !important;
}
.iconName4 {
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% - 7px);
    letter-spacing: 0.04em;
    line-height: 130%;
}
.iconName4:hover {
    cursor: pointer;
}
.def:hover {
    cursor: pointer;
}
.icon4 {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.button2 {
    margin: 0 !important;
    position: absolute;
    top: 15.5px;
    right: 15.5px;
    border-radius: 10000000px;
    background-color: #2e2f35;
    border: 1px solid rgb(255, 255, 255, 0.1);
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    z-index: 4;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
}
.button2:hover {
    background: #757780;
}
.button2:active {
    background: #35353f;
}
.modal {
    width: 40vw;
    min-width: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    box-shadow: 0px 0px 40px rgb(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #23242a;
    border: 1px solid rgb(255, 255, 255, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 24px;
    gap: 32px;
    text-align: center;
    font-size: 24px;
    color: #17181f;
    font-family: 'Material Icons';
}

/* Start of step 2 */
.number {
    width: 100%;
    position: relative;
    font-size: 40px;
    letter-spacing: 0.04em;
    line-height: 48px;
    font-weight: 900;
    font-family: 'Basement Grotesque';
    color: #fff;
    text-align: center;
    display: inline-block;
}
.currentPosition {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 19.2px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    color: #daff00;
}

/* Discord */
.customIcon {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 13.6px;
}
.discordButton1 {
    position: relative;
    letter-spacing: 0.05em;
    line-height: 19.2px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Source Sans Pro';
    text-align: left;
}
.discordButton {
    align-self: stretch;
    border-radius: 8px;
    background: #5865f2 !important;
    height: 48px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 32px 8px 30px;
    box-sizing: border-box;
    gap: 8px;
    z-index: 3;
    font-size: 16px;
    color: #fff;
}
.discordButton:hover {
    cursor: pointer;
    background: #7b85ec !important;
}
.discordButton:active {
    cursor: pointer;
    background: #5865f2 !important;
}

/* Email input + button */
.inputTwo {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 16px;
    color: #fff;
    font-family: 'Source Sans Pro';
}
.inputContainerTwo {
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #23242a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    padding: 8px 16px 8px 12px;
    text-align: left;
    font-size: 16px;
    color: #fff;
    font-family: 'Source Sans Pro';
}
.textTwo {
    flex: 1;
    border: none;
    position: relative;
    background-color: #23242a;
    color: #d1d1d2;
    outline: none;
    font-size: 16px;
    font-family: 'Source Sans Pro';
    letter-spacing: 0.02em;
    line-height: 25.6px;
}
.copyButton {
    border-radius: 8px;
    background: linear-gradient(135deg, #fff500, #bdff00);
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    box-sizing: border-box;
    font-size: 16px;
    color: #17181f;
    font-family: 'Material Icons';
    cursor: pointer;
    border: none;
    margin-left: 8px;
}
.copyButton:hover {
    background: #e8ff67 !important;
}
.copyButton:active {
    background: #adcc02 !important;
}
.iconNameTwo {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 8px);
    letter-spacing: 0.04em;
    line-height: 130%;
}
.iconTwo {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputParent {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}
