.LandingPage {
    background-color: #17181F;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    justify-content: center;
}

.NavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    height: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.NavBarLeft {
    display: flex;
    gap: 1rem; 
    align-items: center;
}

.Title {
    width: 100%;
    color: var(--text-primary, #FFF);
    text-align: center;
    font-family: var(--Font-Style-Heading, "Basement Grotesque");
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    text-transform: uppercase;
    flex: auto;
    padding: 0 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    letter-spacing: 0.17669rem;
    margin-top: 2rem;
}
.Title span {
    background: var(--Gradient-Default, linear-gradient(135deg, #FFF500 -0.06%, #BDFF00 100.02%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ImagePane {
    display: flex;
    width: 100%;
    gap: 5%;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    justify-content: center;
}

.ImageCluster {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-sizing: border-box;
}

@media (max-width: 900px) {
    .ImageCluster img {
        max-width: 100%;
    }
    .NavBar img:nth-child(2) {
        display: none;
    }
    
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.PopUpContainer {
    position: fixed;
    z-index: 2;
}