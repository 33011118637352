.Pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  
  .slider {
    height: 250px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  
  .slider .slide-track {
    display: flex;
    width: calc(250px * 18); 
    animation: scroll 40s linear infinite;
  }
  
  .slider.reverse .slide-track {
    animation: scroll-reverse 40s linear infinite;
  }
  
  .slider .slide {
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    flex: 0 0 auto;
  }
  
  .slider .slide img {
    width: 100%;
    display: block;
  }
  
  /* .slider .slide-track:hover {
    animation-play-state: paused;
  } */
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 9)); 
    }
  }
  
  @keyframes scroll-reverse {
    0% {
      transform: translateX(calc(-250px * 9)); 
    }
    100% {
        transform: translateX(0);
    }
  }