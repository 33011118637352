@import url('https://fonts.googleapis.com/css2?family=Material+Icons:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap');

.yellow_button {
    background: #daff00 !important;
    border: none !important;
    border-color: none !important;
    color: #17181F;
    width: 100%;
    min-width: 10rem;  /* Set a minimum width */
    max-width: 20rem;  /* Set a maximum width */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    height: 2.5rem; /* Button height */
    gap: 0.5rem; /* 8px */
    border-radius: 0.5rem; /* Rounded corners */
    font-weight: 900;
    font-size: 1rem; /* 16px */
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 0.05em;
    font-family: 'Source Sans Pro', sans-serif;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none; 
}

.yellow_button .content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.yellow_button .text {
    color: #17181F;
    font-size: 1rem; /* 16px */
    font-style: normal;
    font-weight: 900;
    line-height: 1.2rem; /* 19.2px */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.yellow_button .material-icons {
    font-family: 'Material Icons';
    font-size: 1.25rem; /* Adjust size as needed */
    line-height: 1.25rem; /* Match line height of text */
    letter-spacing: 0.04em;
    color: #17181F;
    margin-left: 0.5rem; /* Add space between text and icon */
}

.yellow_button:hover {
    background: #e8ff67 !important;
}

.yellow_button:focus {
    background: #daff00 !important;
    border: 2px solid #43C4EE !important;
}

.yellow_button:focus-visible {
    background: #daff00 !important;
    border: 2px solid #43C4EE !important;
}

.yellow_button:active {
    background: #adcc02 !important;
}