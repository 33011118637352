$modal-fade-transform: scale(2) !default;

// Import Bootstrap functions, variables, and mixins
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Custom modal transition
.modal.fade .modal-dialog { 
  transition: transform 0.3s ease-out; 
  transform: scale(2); 
}

// Import Bootstrap modal and transitions components
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/transitions";
