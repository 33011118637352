@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons:wght@400&display=swap');

.DisplayCards {
    text-align: center;
    color: #FFF;
    padding: 2rem 4rem;
}

.DisplayCards h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
}

.DisplayCards h2 span {
    background: var(--Gradient-Default, linear-gradient(135deg, #FFF500 -0.06%, #BDFF00 100.02%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.DisplayCards .CardContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: stretch;
}

.DisplayCards .Card {
    background-color: #23242a;
    padding: 2.5rem;
    border-radius: 0.5rem;
    flex: 1 1 18.75rem;
    max-width: 18.75rem;
    margin: 0.5rem;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-sizing: border-box;
}

.DisplayCards .Card p {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    width: 100%;
    position: relative;
    letter-spacing: 0.02em;
    line-height: 1.5rem;
    font-family: 'Source Sans Pro';
    color: #e8e8e9;
    text-align: left;
    display: inline-block;
}

.DisplayCards span {
    background: var(--Gradient-Default, linear-gradient(135deg, #FFF500 -0.06%, #BDFF00 100.02%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fonts {
    position: relative;
    font-size: 3.75rem;
    line-height: 130%;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Basement Grotesque';
    color: transparent;
    text-align: left;
    -webkit-text-stroke: 0.125rem #e8e8e9;
    white-space: nowrap;
    min-width: 12.5rem;
}

.Card:hover {
    background: linear-gradient(135deg, #FFF500, #BDFF00);
    color: #17181F;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.Card:hover .fonts {
    color: #17181F;
    -webkit-text-stroke: 0;
}

.Card:hover p {
    color: #17181F;
}

@media (max-width: 768px) {
    .DisplayCards .Card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .DisplayCards .fonts {
        font-size: 5vw;
        min-width: 0;
    }
}
