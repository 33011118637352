/* Mobile-specific styles for NavBar */
@media (max-width: 600px) {
    .NavBar {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem;
        align-items: center;
    }

    .NavBar div {
        display: flex; 
        align-items: center;
    }

    .NavBar img {
        height: 1rem !important;
        margin-left: 0.25rem;
    }

    .NavBar img:nth-child(2) {
        display: inline; /* Override the desktop display none rule */
    }

    .yellow_button {
        padding: 0.25rem 0.5rem; 
        margin-left: 1rem; 
        display: flex;
        align-items: center;
        justify-content: center; 
        white-space: nowrap;
        min-width: fit-content; 
        width: auto;
    }

    .yellow_button span {
        font-size: 0.75rem !important; 
        white-space: nowrap; 
    }
}



/* Mobile-specific styles for AssembleTeam */
@media (max-width: 600px) {
    .AssembleTeam {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .AssembleTeam h2 {
        color: var(--text-primary, #FFF);
        text-align: center;
        font-family: var(--Font-Style-Heading, "Basement Grotesque");
        font-size: 2rem; /* Adjusted size for mobile */
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        text-transform: uppercase;
        width: 100%;
        max-width: 100%;
        word-wrap: break-word; /* Ensure text wraps */
        margin: 0 auto 1rem auto;
    }
}

/* Mobile-specific styles for ImageCluster */
@media (max-width: 600px) {
    .ImageCluster {
        padding: 1rem 0;
    }

    .slider {
        height: 150px;
    }

    .slider .slide {
        height: 100px;
        width: 150px;
    }
}

/* Mobile-specific styles for DisplayCards */
@media (max-width: 600px) {
    .DisplayCards {
        padding: 1rem;
    }

    .DisplayCards h2 {
        font-size: 2rem; 
        font-family: var(--Font-Style-Heading, "Basement Grotesque");
        font-weight: 800;
        line-height: 130%;
        text-transform: uppercase;
        letter-spacing: 0.17669rem;
        
    }

    .DisplayCards .CardContainer {
        flex-direction: column;
    }

    .DisplayCards .Card {
        width: 100%;
        padding: 1rem;
        margin: 0.5rem 0;
    }

    .DisplayCards .fonts {
        position: relative;
        font-size: 2rem;
        line-height: 130%;
        text-transform: uppercase;
        font-weight: 900;
        font-family: 'Basement Grotesque';
        color: transparent;
        text-align: center;
        -webkit-text-stroke: 2px #e8e8e9;
    }

    .DisplayCards .Card p{
        text-align: center;
    }
}