@import url('https://fonts.googleapis.com/css2?family=Material+Icons:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap');

.AssembleTeam {
    text-align: center;
    color: #FFF;
    padding: 2rem 4rem;
}

.AssembleTeam h2 {
    font-family: var(--Font-Style-Heading, "Basement Grotesque");
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
}

.AssembleTeam p {
    position: relative;
    font-size: 1rem;
    letter-spacing: 0.02em;
    line-height: 150%;
    font-family: 'Source Sans Pro';
    color: #fff;
    text-align: center;
    display: inline-block;
}

.Buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.create-button {
    background-color: black; 
    color: #FFF; 
    border: 1px solid gray;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #333;
}

.yellow_button {
    background: linear-gradient(135deg, #FFF500 0%, #BDFF00 100%);
    border: none;
    color: #17181F;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.yellow_button:hover {
    background: linear-gradient(135deg, #FFF500 0%, #BDFF00 100%);
}
